import React, { Component } from 'react';


class Poster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: this.props.url,
            name: this.props.name,
            author: this.props.author,
            showInfo: false,
        }
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter(){
        this.setState({showInfo: true});
    }

    handleMouseLeave(){
        this.setState({showInfo: false});
        //
    }

    render() {
        return(
            <div className={"poster"} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                 <img alt={this.state.name} src={this.state.url + ".png"} style={{width: "100%", height: "auto"}}/>
                {this.state.showInfo &&
                    <a href={this.state.url + ".pdf"}>
                        <div className={"posterDetails"}>
                            <p className={"sub_sub_title_div"}>{this.state.name}</p>
                            <p>{this.state.author}</p>

                        </div>
                    </a>
                }

            </div>
        );
    }
}


export default Poster;