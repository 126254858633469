import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as React from "react";
import {Component} from "react";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";
import {Button, Container, Offcanvas} from "react-bootstrap";
import About from "./Components/About";
import Call from "./Components/Call";
import {AVAILABLE_COLOURS} from "./params";

import history from './history';
import ReactGA from "react-ga";
import Program from "./Components/Program";
import Exhibition from "./Components/Exhibition";

ReactGA.initialize("UA-157191046-1");

history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});


class App extends Component {
    constructor(props) {
        super(props);
        this.ga = props.ga;

        this.state = {
            top: "",
            showAbout: (this.props.showAbout !== undefined ? this.props.showAbout : false),
            showCall: (this.props.showCall !== undefined ? this.props.showCall : false),
            showProgram: (this.props.showProgram !== undefined ? this.props.showProgram : false),
            showExhibition: (this.props.showExhibition !== undefined ? this.props.showExhibition : false),
            color: AVAILABLE_COLOURS[Math.floor(Math.random()*AVAILABLE_COLOURS.length)],
            video_url: process.env.PUBLIC_URL + "/media/finalPCD.mp4",
        };

        // change colour of header in responsive
        document.documentElement.style.setProperty('--main-color', this.state.color);
        document.documentElement.style.setProperty('--main-color-alpha', this.state.color + "aa");

        // window.addEventListener("contextmenu", e => e.preventDefault());

        this.openAbout = this.openAbout.bind(this);
        this.openCall = this.openCall.bind(this);
        this.openProgram = this.openProgram.bind(this);
        this.openExhibition = this.openExhibition.bind(this);
        this.isEnabled = this.isEnabled.bind(this);
    }

    isEnabled() {
        return (!this.state.showAbout && !this.state.showCall && !this.state.showProgram && !this.state.showExhibition);
    }

    openAbout() {
        history.push(window.location.pathname);
        const showAbout = this.state.showAbout;
        this.setState({
            showAbout: !showAbout,
            showCall: false,
        });
    }

    openCall() {
        history.push(window.location.pathname);
        const showCall = this.state.showCall;
        this.setState({
            showCall: !showCall,
            showAbout: false,
        });
    }

    openProgram() {
        history.push(window.location.pathname);
        const showProgram = this.state.showProgram;
        this.setState({
            showProgram: !showProgram,
            showExhibition: false,
        });
    }

    openExhibition() {
        history.push(window.location.pathname);
        const showExhibition = this.state.showExhibition;
        this.setState({
            showExhibition: !showExhibition,
            showProgram: false,
            showAbout: false,
        });
    }

    componentWillUnmount() {
        // this.socket.close();
        console.log("Component Unmounted");
    }

    componentDidMount() {
        let navbar = document.getElementById("navbar");
        let footer = document.getElementById("footer");

        this.setState({top: navbar.offsetHeight + "px"});
        this.setState({bottom: footer.offsetHeight + "px"});

    }

    render () {
        return (
            <Container>
                <NavBar/>
                <Container>
                    <Offcanvas className="about_call_div" style={{marginTop: this.state.top, marginBottom: this.state.bottom}} show={this.state.showProgram} backdrop={false} placement={'start'} >
                        <Offcanvas.Header style={{paddingTop: 0}}><Button variant="link" style={{textDecoration:"none", fontSize:"3rem", width:"fit-content", color: "var(--main-color)", padding: 0}} onClick={this.openProgram}>✕</Button></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Program />
                        </Offcanvas.Body>
                    </Offcanvas>

                    <Offcanvas className="exhibition_div" style={{marginTop: this.state.top, marginBottom: this.state.bottom}} show={this.state.showExhibition} backdrop={false} placement={'start'}>
                        <Offcanvas.Header style={{paddingTop: 0}}><Button variant="link" style={{textDecoration:"none", fontSize:"3rem", width:"fit-content", color: "var(--main-color)", padding: 0}} onClick={this.openExhibition}>✕</Button></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Exhibition />
                        </Offcanvas.Body>
                    </Offcanvas>

                    <Offcanvas className="about_call_div" style={{marginTop: this.state.top, marginBottom: this.state.bottom}} show={this.state.showAbout} backdrop={false} placement={'end'} >
                        <Offcanvas.Header style={{paddingTop: 0}}><Button variant="link" style={{textDecoration:"none", fontSize:"3rem", width:"fit-content", color: "var(--main-color)", padding: 0}} onClick={this.openAbout}>✕</Button></Offcanvas.Header>
                        <Offcanvas.Body>
                            <About/>
                        </Offcanvas.Body>
                    </Offcanvas>

                    <Offcanvas className="about_call_div" style={{marginTop: this.state.top, marginBottom: this.state.bottom}} show={this.state.showCall} backdrop={false} placement={'end'}>
                        <Offcanvas.Header style={{paddingTop: 0}}><Button variant="link" style={{textDecoration:"none", fontSize:"3rem", width:"fit-content", color: "var(--main-color)", padding: 0}} onClick={this.openCall}>✕</Button></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Call/>
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/*<P5Wrapper isEnabled={this.isEnabled}
                        color={this.state.color} socket={socket}
                    />*/}
                    <video src={this.state.video_url} loop={true} autoPlay={true} controls={false} style={{marginTop: this.state.top, marginBottom: this.state.bottom}}/>
                </Container>
                <Footer openAbout={this.openAbout} openCall={this.openCall} openProgram={this.openProgram} openExhibition={this.openExhibition}/>
            </Container>
        )
    }
}

export default App;
