export let sketches = [
    {id: 1, name: "Interfering Orbits", author: "Luís Espirito Santo", url: "https://openprocessing.org/sketch/1702756/embed/?plusEmbedHash=MzQyMjMyNGY5NWRhOTc1Y2JlOTQyNWE0NTM4Y2Y0NzEwYTU5YWM4NTFkMjUzMTllN2U1NjllZWFlZmEzMzAxYmVkMTgyMmI2YjJjMjgwN2RlYWE3NDQ1MDllMDI3Yzk1Y2ViMWUwY2RmN2YzOTQ2OWQ3YmVmYmI4MmIzYzQ2M2FLVm0vQ3U0TVZhVTJaV0VjZmR3WGRZUW8vakozOTBXbE5ZYlp6eVpXNHg2N2wvSFdGTmtiVGFiMHh3U2FxaWsrOVBTZzh0eVdpWHZhdVJzK2lkL1FrQT09&plusEmbedFullscreen=true"},
    {id: 4, name: "Glitches for PCD2022", author: "André Oliveira Cebola", url: "https://openprocessing.org/sketch/1702760/embed/?plusEmbedHash=MjRiZTk1NTkxMmIxOTc4M2U3NzM3NzA5NDhmYmRiYTc1Mzg3OGRjNDgxYzEyZmU3YTBlYjQxZDAxNTMxOThhN2I0MDM3MzY3ZWFmMzhlYjNmODllYjZiM2M1ZjcxNWFjNDlmNDZiOGY5NmQ1MzFmZDkxZThlM2FkOWU5ODQ5NzlnckNKZnFWK3MvcHgvYU5PNWtKR1JoTkVQcWhJeHcxcFdSWElKT04vdDQ1NTFRMjdGZlo5TVNGVTRmVW91WFVZajI5dXQybDdGR2VlN0JjdGs2YnliZz09&plusEmbedFullscreen=true"},
    {id: 9, name: "Noisy City", author: "João Santos", url: "https://openprocessing.org/sketch/1702764/embed/?plusEmbedHash=YTg5NDM5NjBlZDc2MzFmYmE5NWJmZDk5NjU2ZmE2Mjg5ODI5YmFiNmZmMjAxNTYwZTg1NmUyNmYyMmNkZjk1OWFmMDNiM2U0NjA2MmQwN2QzZTI1MTA4YzViMzc0NDQ4NjBlN2QyYmNlOTBjODk1ZmFhYmE1M2VkNTg4NjM3ZDdSTHRTbzM3RVB0MHVJQnFwVTR6bW5WUnVMcWxscUFGWGxpY1V5TTJEdGN0TmFGSmdxYmkyRE9rMnRXYnRnWEFCV1h1NHhKUUdwU3pyZmtWN2dUYTExQT09&plusEmbedFullscreen=true"},
    {id: 10, name: "2(+1)D", author: "Eduarda Duarte", url: "https://openprocessing.org/sketch/1702768/embed/?plusEmbedHash=MThiZWU4NDVjNDRmOTFmODk1MTBhOTM5YmE5NzAzMmMzYzYzMDc0ZDRmMjIxZjdlNzg3ZDUzNjU2NzI2NDBiNDc0NDhlMWU4MWI0NjYyZTM2YTMzNDY4ZjcxNDFkYThjMWI0NzdmMDA5NTU0NmUxMDYyYTBmOGQ3YTYxM2IzMTZVeWpKTEI0L2RNL292UnlqSVlHY2xSdUJNRUR1azhjTzFmemdHbzk0Wmo3RXRla01jM0M1TVc4alhFQ0xhV1hac0Q3RTJkTlNPckRWZWZDeVB5SGxMZz09&plusEmbedFullscreen=true"},
    {id: 12, name: "A Ver as Moscas", author: "Alexandra Oliveira", url: "https://openprocessing.org/sketch/1702769/embed/?plusEmbedHash=ZWNjODk5MDAwOGZiMDIyZTFiZWFmMmU5ZmRiMjYyNmQ4MzdiM2ZkMWNkNmJhNjA3ZDdlYjlmNzQzNmE5ZTBmZTUxOWE3NDdiN2I1NjkzN2FmMTRlMTZiNDNhYjVhNmVhOTZiZDkxMjY2NmExYWU2NWQzYmRkMDA4OGQyNDliM2F3VUxFU290aEFndVI3VUpuTlVCTnFEOUw1QjU4TWdETFhRTnRZczZORUtKckV4VENkYThlaXBZUGtmSVVUTmJsU2RkR3FVZE03OENGcjlid1lxYXM2QT09&plusEmbedFullscreen=true"},
    {id: 16, name: "PCD2022@Coimbra MandyBrigwell", author: "Mandy Brigwell", url: "https://openprocessing.org/sketch/1702771/embed/?plusEmbedHash=MzAwMmM4ZWIxOGE0Y2U2YTBkYmJkMzc0MzlmZGE0YjkzNjFlMjZlMTEzYzJmNGZlYmNkZGE2MTEwZDNiZDUzM2NlNzhmYTY0MjBlYjQxYjA5MjZkZmMxODkxMDBiYjJhYjkwNzY3MjM1NmRiNTg1OWQzNGMyMTIzNjZkMWQwMjUvWnNPZXFuSjU3akdNQm5WWWNPTTlmVWt3SXkzRUlIalg1Y0NaU1NlWmkxWlRWcGpYZGZ6d2lpaGZDNlF1MUJ1RUJVNURsYksxR3lTVDI0dWl6UmlOUT09&plusEmbedFullscreen=true"},
    {id: 18, name: "Waves", author: "Joana Araújo", url: "https://openprocessing.org/sketch/1702775/embed/?plusEmbedHash=NGMxYTBkN2Q4MzI5NzkzOWI2OTk1OTIwZWJjMjBmMTIyNjkxMjc5MjZlZmZkZDRkN2JiNWU4NWUzOTg2MTNhNGRiNTg3Y2JkMDRlYmRkMjg0YjFiNWQyNmFkNTE4YmVkYmEzNjJhZTdiOTdkYzI4ODZiMmU4Y2U2Njk0MjNkZGNMSDV0bC9aQlZXNC9wZldXcXNEUlA5cktiMUtMbVlaMjYvQThVekxZeExYeTN1aG9kVGo3QXVYMEF0NURvbEd1YitCU2dFNDBDNWIxVVBQK2NHSUxsZz09&plusEmbedFullscreen=true"},
    {id: 71, name: "Four Different Modules", author: "Jesús Ibáñez Martínez", url: "https://openprocessing.org/sketch/1702776/embed/?plusEmbedHash=ZDIwYTRmNzJlYTBlOTlkY2M2YWQ5Y2QyNzU1OGMyYmZiYzFjNGI4ODc0YzAxMmNhZDY2MzI2MDU0OWRmZTJjM2VlNDc0N2VkMWY2YThlOWY5ZjU3MzU0OWI1NzY5Njg5MjVjYmYxMjY3MTEzOWMyMDlkZjA0MzQyZDFhMzA2Zjd0QStGbUZFQXA4YVZJUCtyK1gwQ2hZTGFJaisvV2Q2YktselBraU9tMWwvZzcvZnZCQ2Zkd3VSck8vQmJ3M3FjdFZYRWdzcGZMckpIWGFHTklDcWYzUT09&plusEmbedFullscreen=true"},
    {id: 19, name: "Phillotaxic Spirals", author: "Rodrigo Neves", url: "https://openprocessing.org/sketch/1702777/embed/?plusEmbedHash=ZmJlMTU4NjMzNWM5Njc4MmZhYTNmNGFkMmI1ZjFhZWJjM2M3MjNkNjJiMDMwNWM2MzVkZTJlMmU4ZjExMmRmOWYwNGJmZDRmYzY5ZThlMzk5NmVlMjQwNjQxZWY5NmEyNzk1Mzk3ZDU0NjNlZWQzMzM2ZTNhNmVhYmQ5OGFmYTE4WnBwaUJ5bjhvcHJOMjlWalRTSEM1Tlg3SURyaHR4cmlGSkpUYkY3Smp0bU1xT3pRQVlSQk41R3JkYzEyT3FLVHF6YVNlK25oYUx2TmRHemVZMzgwQT09&plusEmbedFullscreen=true"},
    {id: 28, name: "Proximity, Expansion, Continuity ", author: "Takayuki Sato", url: "https://openprocessing.org/sketch/1702788/embed/?plusEmbedHash=Y2E1MTExNTY4NjBmYmRiMjVkYWE4M2E4ZDlkNDhmYmE2MmExYmEyNDhjMWNiYWEzNWFkZDI1YWNkYWRlODE3NTljMTA4YTZjMzY0ZjY0Y2E1MDIwM2U4ZDA4Zjk3ZGNlZTkyOGU4MGFkOTZiNjRiZTM4YzRiMGUwY2Q5ZjYyOWNpQjk0T0lScjFxTk5KNW5tTVZyY2J2ejZuL25vRWJVZWtqMkJkcjk2UXNrSytWa2ZYOVc2V3Z1amxKSzZHb21ubWxFN09lMk5QY0xBV0lTd2NaK2d5dz09&plusEmbedFullscreen=true"},
    {id: 69, name: "Four Different Modules", author: "Jesús Ibáñez Martínez", url: "https://openprocessing.org/sketch/1702789/embed/?plusEmbedHash=YjQ2NDg3ZDBmY2IwMjhhNTg4NmNmYjhhZDg1NzU5MjQzMzRhNjBhNmRkNWRjZDU3MWIzNDQ0YmQ3MGNhZjlhZTMyZGRlNTNiNWMwY2U2ZTA4ZmEwMzk4NDMxZmY3MTAzMDRjYTYyNGU0Y2FlNjU2ZjFjYmQ5MmVmNDI3NWViZmZCNDFWWUVQUHRqY1VKWGl0OFE0R2VGU3JQajZqMVRxY2tnRWtIcy8rNlBscDBCNVR0ejJEbXV5OWlOZnJNcUxVUCtlY2tZWEpBTDNWTXR6SGUvMHVFQT09&plusEmbedFullscreen=true"},
    {id: 32, name: "Proceeding", author: "Athanasios Katsougiannis", url: "https://openprocessing.org/sketch/1702793/embed/?plusEmbedHash=NTQ3YmVkMmY5ZTUxNTZjMWFjZDk2Zjg1YTk2YTI1NzNjZTFmNGM4YzljYzBkYjQ1Njk4MzgxYmU5MTJmMjg5Y2U1M2U3NDQ2NjdiZWVkZjY2YWQ5OTllZTU0M2U4N2RhNGZjOGIyOWJjZDAwNzJhZjg4ZWU5NjI1OTA5MDU0NDU3dndkRGZQUjJYdmRkUTIxQllFZVNleTZjQVdXRVh1VW5Ra3pTMStKc1I5MnZhVng2bDFpdXM4QzhWZWhjVjdFQlNTTVVHK0s1ZG15MzE4KzA5N0N1UT09&plusEmbedFullscreen=true"},
    {id: 33, name: "Evolving Together", author: "Athanasios Katsougiannis", url: "https://openprocessing.org/sketch/1702795/embed/?plusEmbedHash=YmQ2YTgzOTk1M2U1OGM4OTY0ZDdmMjVlMmJlODM1ZDBjOGUwMTY2NDU2ZjdhZmI0YTljZDk3N2Q5MDg1ZjlhMzlhNTllZDIwY2E5MTAwZDUzYmQ3NzcwMTc3NzdiYWRmNmExMzQ1ZWE4ZWFjOGY1ZjNmYzE5NDFmMTgxNGQ1NWFXemI5RVJ0Ujk0aEl4WnZUSFdkS0hLT0MxNklLSml5ejNhQlVNZzIxakNjanNvNUcvNUU5R0p6eUt5aHByUzhRRmNTUkt2c0x3RkYrTDcwSDdvcWpRQT09&plusEmbedFullscreen=true"},
    {id: 64, name: "Exchanges", author: "Thibaut COUDROY", url: "https://openprocessing.org/sketch/1702798/embed/?plusEmbedHash=ZmM3ZmU4NmQ0OGM0NWYyZjYxZTRhOGNjYTA3ZmM2NzM5YjRhMjQyZmI0ZWQxMzc4Y2JjMTcxMWU1YjllMDBlZTAwZWQzNzhjMGZkZDdhMDhiNzg5ZTYzZTIzYThjYzEyNjRmYmJjMjA0Y2FkMzBhOGZkMTI0YjlmNGE2OTZiZGU4R0FVOXZXNGZYb2pvbFdsVUNKc29yenBEeXRLMnVFY05LaUZneFNQdTZpRy81SEh3VC95S1p0cHBTN1FCSGFJWFY0OWR1b0E2ci9TK1dDTFRqR21YQT09&plusEmbedFullscreen=true"},
    {id: 34, name: "Heartbeat for the People", author: "Ryan Green", url: "https://openprocessing.org/sketch/1702799/embed/?plusEmbedHash=ZWE4OTA0ODliMDIyZGYwNWFmYzAyMjZhZjYyNDJhNWQ4ZTM2ZGRlYzI2ZGNjNTE2OGZlZDA5NzRhYTc3YjE3ZjU4M2ViYmJkNzNkNGRkYTA0MWFiMDQ5YjQ2YjBmMTBmNmM5YmJmYmFlY2QxMzY0ZGNjODNiMGM2NmI4MjM5ZGRTSWRydTFydG9rajFrL1FaY0NadjYrOGRERHFvSUFJTXpBZ3VUVk81dUlRZ0JVc2N4d2JYRTN1all5cXFYeWUyVXM2YmdFZ05kc2x5LzdIeGRnTi9Bdz09&plusEmbedFullscreen=true"},
    {id: 35, name: "A Tocoin of Affection", author: "Jayden Serenari", url: "https://openprocessing.org/sketch/1702800/embed/?plusEmbedHash=NTUwZjAzNjJiZTZkOGVmMWEzMmQ3MGQ3YzI2ZmRjOWE0YmUyYjM0OTA0NjhjNTM0ZTU2ZmNjMGQ5N2ZjNmEyYTA4OTk1MGZhOGVhYTFlMjliYmIxM2Y2YWRiYmMxN2EzZmU2NDE0ZWU1MjYxZGFlM2E1N2YzNWRmMzMwMjg2M2Nka0RJcDZTY2JRQVZTRDlDa2dXY2dYeTRIU0ZLVk1lNXRiQjZpdXBpc0VLMXUrZ3pNQXMyd2hYQm5HY1pGeFd5VFdjVGdrWksyNFFjaE9MbVN0YWs1UT09&plusEmbedFullscreen=true"},
    {id: 37, name: "Smoke and Mirrors", author: "Timo Hausmann", url: "https://openprocessing.org/sketch/1702809/embed/?plusEmbedHash=Mjg0M2QxNmZlOWEzMThkNGQzOTJjMWUyOTk1N2Q1MWQ5MGVhZjUxZjUxNjY0YjFmYmQxZWEwNzQxMWIzYzZjN2I0MDkxN2NjMzY2OWRkYWY3ZTBiN2JjNDFkMDU0OTA4ZGQxMTU5OGQ5ZDY1ZmZkMDE5Yjk3NDExYTk3MWM1NzQyT3N3dzhuQWZFOUsvOUJ5TEZYRy9nQ1ZBeHA0MDRjNnIzdE1IaHh0Y1dtS3UyK3p6WXM5OFdIRjgweVVSaEx2N0RoUnVHdmdVNlVyMW1DLzVoV0IrQT09&plusEmbedFullscreen=true"},
    {id: 62, name: "Vertigo's Title Sequence Reinterpretation", author: "Jéssica Parente", url: "https://openprocessing.org/sketch/1702810/embed/?plusEmbedHash=NThjNzM2NzdmNjVhMWMzY2FlNDE1MDZjNjViZjc3MzAxNzhmMGFjMjFlMjcxY2Y3N2Q4NzZiODgyMjhkZWZmMjU2MGMzYzdkODVmMGI0OWI3MTJjNTM3OGE4YjQyNmU5NzBkYjg0NTVhNzZlYmU0YjRhM2EwZDU0ZGMyZDQ1NDVLY2dSZ3FLQzlUcHUzcjM5a1MzVmJrbEVsUVBuclNMSXgyTVBOeDlhUVIxVzlDZVpsUmhDTFdoNnNKYkdBc29aMjJkSlVXZUxkZzhkRlR0NzdZeFRpQT09&plusEmbedFullscreen=true"},
    {id: 38, name: "Attract/Repel", author: "Timo Hausmann", url: "https://openprocessing.org/sketch/1702812/embed/?plusEmbedHash=MjIwNTUzN2Q3ZmE3OGI4OTQyNTBkN2E4NTllYTdjNzYzOTRlODVkNGRhMWIwOWUwZjk1YTg5ZmU4NDEwZjg1MWNkYmU4YWZjOGU4ZmU5NDRiMmRmYmNjMzg5YjlkMWI0ODViY2YxNmZiZDU2YTc3YjgwNmViMDhlNzAxZjA4ZTErVkJQWjVld0xzdmV2eVhxT0hLQlljL01xT3ZSczl0b2NxaGsrWWdoMkRTL2F2ZkVMUlZ0MkFJam5NYWJMdURyNVNZZnl5bktEUjZlZmFxdUJuUVR1UT09&plusEmbedFullscreen=true"},
    {id: 39, name: "Poly Flower", author: "JPupper", url: "https://openprocessing.org/sketch/1702815/embed/?plusEmbedHash=ZGFmM2E4MTM5NzU1NzFkNzAyZmQxNmY1YjNmMjRmM2U2MmM2ZjQzMDM1YTM4ZWI4NGI4YzZhOGZjOGQ2ZmU4NjJlMmM2ODAxNmM2ZDk3ZjkyYmEyMjNjZDkxZTJjZmM2YmY0Yzg4M2ZmMTcxMGEyODQxOWFjNGNlMzYyOTBmOGExbGNWbThIdGtEeVh6RTJqdmNUR3JQQm5rYlV1cUIyRExLSVFiSTlBcjNTK2Y4MjBLOXBoR0gydWZMa2pqanhQcklybWNhREJ3VGlGWmNWU0puMlhRQT09&plusEmbedFullscreen=true"},
    {id: 59, name: "Rising Together", author: "Thibaut COUDROY", url: "https://openprocessing.org/sketch/1702816/embed/?plusEmbedHash=MDhlYjdhZTA1NDA0NjEyMDQ2ZTRhMmY1ZDZjNTM5ZmI4NWY0ZjQwMDQ4YmJiNjkyMWYwOTE1NDE3YzUxMzg5YzM5NWE4YjM0MTU1MDQ3YTY5ZjkwMGExYzMyOTgzNzRkNzhjYWQ2ZDQ4NmRjNGIwYWMwZjBmMTJkMDcyZTE3MzcrcmxibE5hR1FLNmRYS3psblBTQzF6SEw1eFl5ZThOTU1tOGVmQ0xoWWJjNlA2N1dTR01CdFhBa0dkRkhtUzI2SjhBWHBWd3BuUWIxb2lEdS9YL1gyUT09&plusEmbedFullscreen=true"},
    {id: 40, name: "Bolas", author: "Luís Gonçalo", url: "https://openprocessing.org/sketch/1702818/embed/?plusEmbedHash=ZjFmMDUyODNiZjQ3OGJlZDFhYTlhMjkyYTQxMjRhNGZhNjQzNWExYzM1YzAyYzI4M2M4MDkwMDcxZjM4MzhiZDhhZjk2OWY2NzM3OTAzNmI1MGJlNGQ2M2YwYWI1NTY2YzE0OTY3NDNlMzAyOTRhNWM3Y2ZlNTcyNGVhMDY0ZDRqNGF6OGwwQXlESnFqbmhPclliYU1RVGNYOUxQZE83ald6UHV1UWFXa2VhM0N3UHB6RWZJL2tueVJtMk03Q2tFUzAzVTlrU1VTaVBGQWFkZWhzb3ZEQT09&plusEmbedFullscreen=true"},
    {id: 43, name: "Chain of Communities", author: "Atsushi Tanaka", url: "https://openprocessing.org/sketch/1702822/embed/?plusEmbedHash=Mjk2NDRkNjlmOTk3YzY5NjRiZjI0YjQ3NjFmMDYzNTEyOGEyM2YxMDFiMDQ4ZjQyOTBhMTExN2VjMDE2ZDUxMDBhNjE2ZTYxMjQzYmFhMTg1NzFkOWQ4YjM2NzgxYzgwMDcwYjM1ZDlmODdhNjcxZTFjOTQ3N2Q2YjM3ZDIzMmJBL2NUVlhxTytvelk2N3NyZUZObXRHVDcxcy8xL0Yxd3hQYWtOZjdsVDE5alJ3am1OOFpLQkxwQzBtL1lDRVBKdVF0SEN2Zm1nNE1tK3R6SExHSE1UUT09&plusEmbedFullscreen=true"},
    {id: 44, name: "iii", author: "Jenny Biette Kowalski", url: "https://openprocessing.org/sketch/1702824/embed/?plusEmbedHash=ZDY5NmVkODQ4ZTlmN2RjNjdiMjc1MDkwYzg3MWE4OGUyNGZmNzAxYjdjNjQxMGQ4OWM4ZjRmMGU5NjdiOTZmYjFjYTZkYjA3YWFhNjIxMjgzM2RlMzFiNTczMzUwYTFjMzkyMDczZGQyNGZiNzkwZmYxOTE5MDZlMjAwNWY4NmFaRnA4RFA3R1ZXUkRWaEUwM2Z0emJLa3FqOTNkZ2tZbWQ2czU0V0dqOHBhSm1SQjRGTWN3eTBkSkp4RnpqWi8vMHRBVlp4WWszVDZ1aGZTZm5ERWs0Zz09&plusEmbedFullscreen=true"},
    {id: 55, name: "Up and Down", author: "Felipe Braz", url: "https://openprocessing.org/sketch/1702826/embed/?plusEmbedHash=YWY2Mzc2NTcyZTJkM2ZiMmZiYTAyZWM5YzY1YTYzOTMxYjMwNGY3ZjQ5MTA1ODJiNTk3NzYyNTM4ZmUwY2E3MDFmZTU0ZjY2MDdhMmViNTRjN2I4YmI0ZmYyMGViZTYxZDU2MGIyYTIyYjJkZmViYTIyMzdmYTM2ZGMzMmQ1MTZpRk5XT0l6QzhwRmcyVjh1ZjlVNitFVG1Gbm1Ld0prRnYrWjE3Ujh2SjB4QjNlelRvcU9lQWtQSUdiRnJJWENCVW1KNHROclJYN0I5OC9MVFJmeU9zdz09&plusEmbedFullscreen=true"},
    {id: 46, name: "X-pheres", author: "Pedro Silva", url: "https://openprocessing.org/sketch/1702828/embed/?plusEmbedHash=Y2U2OTY1MGE5MGRkNDVkYzBjZWRmYjAxY2U2MjFlMjcwNmFlYmI4YjYxMjEyYThmNGYyZjJjMzg3NmU0ODRhNDgwNmMyMTA3NDNjNjI2NTEwMjc0MTViZWE4ODc1ZmQxYmQ0M2ExY2FkMWE0MTI5OTU1ZGI0Yjg2Y2JhNzY4NGVIalAyNlVUdGxwZlRzZ05xWmdVWXdUTlJWdC8ydDlUbjBGTDM1M1FBY0d3cW1JYmhLcXlZTGphT0tKYjdqSEZYTnVQd21WSENSODVzVFd0empEOFNWUT09&plusEmbedFullscreen=true"},
    {id: 54, name: "Dithering + Noise", author: "Felipe Braz", url: "https://openprocessing.org/sketch/1702829/embed/?plusEmbedHash=N2EwZDY1NTQxYjY3NDU1MmY1ZWRhMTQ0ZDA0NjBiNzlhMTkzNzRjZDc4YWE0ZTQzZDI5N2VmM2I4NjQ5MGYzNDc2NDBjZmIyYzQ1MjgxOTViNDRmNWM5NDBkY2Y2ZWY4ZTBhYjhlN2JhZDY1MzAwMTk4NGJkZTYxMDE3OTg2OGZOaEdYZXowejM4ZnV0UlNQZTFMSTE5dnhuWCsvb3V3U2x0ekJGYkwrZ1hWaVB4WldoSmw3TXdaRnk4M2JXeUVtbVFTaHdCL2FLU1RlVDRJOUtHeEJvUT09&plusEmbedFullscreen=true"},
    {id: 52, name: "Interference", author: "labstraction", url: "https://openprocessing.org/sketch/1702831/embed/?plusEmbedHash=MmEwMDg3MTI1ODhlNTk5YmFhNTMxMTExMTUzZWZkM2I5ZDBjMDcyOTAzYjE0NWVjMTU0ZGE0MjY5NWM2OWYwYTA2MGIzMTgwYTM4ZmFkOTQ4OGVhZTkzYmI1M2I4MjliNGZjNTdhYTBjMDRmYzYyMDZiNWYzZjc1YTIxZDA5NWJGekJyR29YN0Y2VGNRZk10UnJrMDJ2MTRnMDVmRWJLREhJRGticktHU2tZRnJvaXM4SHJjNndGOXI2OHpQOEllbFowK3BDY2hmNXJtdkc5Sksrc1FvQT09&plusEmbedFullscreen=true"},
]


export let open_submissions = [
    {id: 8, name: "Fragments", author: "Jia-Rey Chang", external: false, url: process.env.PUBLIC_URL + "/media/open_submissions/Fragments_New3.mp4"},
    {id: 22, name: "High Five Wave", author: "Paul Hardman", external: true, url: "https://editor.p5js.org/studio_and_paul/full/n44USDLcz"},
    {id: 24, name: "Cross-stitch Generator", author: "Apostrophe & Slash", external: true, url: "https://habaixa.pt/ponto-cruz"},
    {id: 25, name: "Material Exploration of Plotted Flow Fields", author: "Liz Melchor", external: false, url: process.env.PUBLIC_URL + "/media/open_submissions/liz_melchor_1.mp4"},
    {id: 26, name: "Passing Objects Through The Flow Fields", author: "Liz Melchor", external: false, url: process.env.PUBLIC_URL + "/media/open_submissions/liz_melchor_2.mp4"},
    {id: 41, name: "Lighting the Night", author: "Manuel Mendoza", external: false, url: process.env.PUBLIC_URL + "/media/open_submissions/Iluminando_la noche.mov"},
    {id: 45, name: "Untitled", author: "João Neves", external: true, url: "https://student.dei.uc.pt/~joaoneves/PCDFREE1/"},
    {id: 57, name: "We are Communities that Beat with Multiple Heartbeats", external: true, author: "Jesús Ibáñez Martínez", url: "https://editor.p5js.org/jesus.ibamar/full/NdBmB6-if"},
    {id: 66, name: "Tiny Story Written in Python", author: "Claromes", external: false, url: process.env.PUBLIC_URL + "/media/open_submissions/tiny_story.gif"},
    {id: 68, name: "Community Referral Reward", author: "Marcio Puga", external: true, url: "https://lab.puga.com.br/gog-referral/"},
]


export let posters = [
    {id: 2, name: "Webs of Collaboration", author: "Alexandre B A Villares", url: process.env.PUBLIC_URL + "/media/posters/2"},
    {id: 11, name: "Patch Network", author: "Jean-François Renaud", url: process.env.PUBLIC_URL + "/media/posters/11"},
    {id: 15, name: "Tree", author: "Tiago Charters", url: process.env.PUBLIC_URL + "/media/posters/15"},
    {id: 21, name: "&", author: "Paul Hardman", url: process.env.PUBLIC_URL + "/media/posters/21"},
    {id: 47, name: "No One is an Island", author: "Jenny Biette Kowalski", url: process.env.PUBLIC_URL + "/media/posters/47"},
    {id: 53, name: "Nós", author: "Felipe Braz", url: process.env.PUBLIC_URL + "/media/posters/53"},
    {id: 58, name: "Core", author: "Ricardo Rodrigues", url: process.env.PUBLIC_URL + "/media/posters/58"},
    {id: 60, name: "All Hail the Open Source Type Communities I", author: "João Neves e Pedro Sá Couto", url: process.env.PUBLIC_URL + "/media/posters/60"},
    {id: 61, name: "All Hail the Open Source Type Communities II", author: "João Neves e Pedro Sá Couto", url: process.env.PUBLIC_URL + "/media/posters/61"},
    {id: 67, name: "Codean", author: "Rodrigo Fernández Flores & Renato David Vizuet Cervanetes", url: process.env.PUBLIC_URL + "/media/posters/67"},
]


export default {sketches, posters, open_submissions};