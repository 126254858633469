import React, { Component } from 'react';


class Program extends Component {

    render() {
        return (
            <section className="about-inner">
                <p className="title_div">Program</p>
                <div>
                    <p> Processing Community Day @ Coimbra will take place on the 7th and 8th of December 2022.
                    </p>
                    <p>
                        Please keep an eye on our website or subscribe our mailing list to get updates!
                    </p>
                </div>

                <div className="sub_topic">
                    <h6 className="sub_title_div">December 7</h6>
                    <p className="no-m"><span className="text_bold">14h00 (UTC)</span> — Keynote Speaker — <a
                        href="https://stigmollerhansen.dk/resume/">Stig Møller Hansen</a> (A5.4 room, DEI)</p>
                    <p className="no-m"><span className="text_bold">15h30 (UTC)</span> — Short Presentations Session  (A5.4 room, DEI)</p>
                    <p className="no-m"><span className="text_bold">18h00 (UTC)</span> — Exhibition Opening * (Floor 1, DEI)</p>
                </div>

                <div className="sub_topic">
                    <h6 className="sub_title_div">December 8</h6>
                    <p className="no-m"><span className="text_bold">All Day</span> — Exhibition * (Floor 1, DEI)</p>
                    <p className="no-m"><span className="text_bold">9h30 (UTC)</span> — <i>Interpolations</i> Workshop by <a
                        href="https://stigmollerhansen.dk/resume/">Stig Møller Hansen</a> (E4.1 room, DEI)</p>
                    <p className="no-m"><span className="text_bold">14h30 (UTC)</span> — Touchdesigner Workshop: GLSL and node-based programming languages by Rodrigo Neves (E4.1 room, DEI)</p>
                </div>

                <div className="sub_topic">
                    <p>
                        PCD@Coimbra 2022 takes place physically in the Department of Informatics Engineering (DEI).
                    </p>
                    <p>
                        The event is free of charge, but it requires registration. You can register for all the first day and the workshops <a href="https://linktr.ee/pcdcoimbra">here</a>.
                    </p>
                </div>

                <div className="sub_topic s-t">
                    <p>
                        <h6 className="sub_sub_title_div">Keynote bio</h6>
                    </p>
                    <p>
                        Stig Møller Hansen bought his first computer in 1989 and quickly developed a passion for mixing design and code. Thirty years later, Stig has turned his childhood excitement into his professional career as a Senior Associate Professor, Ph.D., and Head of Center for Design and Visual Communication at the Danish School of Media and Journalism (DMJX). As a researcher and teacher, Stig works at the intersection of design and programming, empowering designers to create their own custom tools using code, while also prompting them to reimagine their creative practices within a computational context. Follow Stig’s activities here: https://linktr.ee/stigmollerhansen
                    </p>
                    <p>
                        <h6 className="sub_sub_title_div">Keynote abstract</h6>
                    </p>
                    <p>
                        Bit by Bit: Introducing Code in Graphic Design Education. As interest in creative coding continues to grow, programming is also making its way into art and design school curricula. Not without problems, though. Because although much of their practice implicitly relies on rules, systems, and math, most designers have a default misconception of code as a rigid, unapproachable, and unforgiving material. So how do you make the designers of the future fall in love with variables, loops, conditions, and functions? In his talk, Stig shares his experiences over nearly two decades of teaching Creative Coding courses for creatives.
                    </p>
                </div>

                <div className="sub_topic s-t">
                    <p>
                        <h6 className="sub_sub_title_div">Interpolations by Stig Møller Hansen</h6>
                    </p>
                    <p>
                        This intermediate-level workshop explores the concept of interpolation. What is interpolation? How have interpolations been used throughout design history? How can we use interpolation today to create eye-catching visual designs? Taking existing design products as its starting point, the workshop will explore and teach new techniques that participants can personalize and use in their work. The workshop assumes a basic prior knowledge of Processing. Bring your own laptop (Win/Mac/Linux) with the latest version of Processing 4.0 installed (download it for free <a href="https://processing.org/download/">here</a>)
                    </p>
                    <p>
                        <h6 className="sub_sub_title_div">Touchdesigner: GLSL and node-based programming languages by Rodrigo Neves</h6>
                    </p>
                    <p>
                        A brief introduction to using GLSL in TD and how to think from nodes to GLSL processes and vice versa. Previous basic understanding of GLSL and Touchdesigner 2021 is important for the class, as the basics will be only briefly mentioned.
                    </p>
                </div>

                <div className="sub_topic s-t">
                    <p>* You can see more info about the exibition call <span className="sublinhado" onClick={this.openCall}>here</span></p>
                </div>

                <div className="sub_topic s-t">
                    <h4 className="sub_title_div">More info</h4>
                    <p>If you have any questions please contact us using the email <a
                        href="mailto:pcdcoimbra@dei.uc.pt">pcdcoimbra(at)dei.uc.pt</a></p>
                </div>
                <p>
                    © 2022
                </p>
            </section>
        );
    }
}

export default Program;