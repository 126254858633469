import React from "react";
import Gallery from 'react-photo-gallery';


const PhotoGallery = () => {

    // let list_photos = photos.map(photo => ({src: process.env.PUBLIC_URL + "/media/photos/" + photo.src, width: (100+Math.random()*200), height: (100+Math.random()*200)}))
    let list_photos = [];
    for (let i = 0; i <= 176; i++) {
        list_photos.push({src: process.env.PUBLIC_URL + "/media/photos/image_" + i + ".JPG", width: (100+Math.random()*200), height: (100+Math.random()*200)});
    }

    return (
        <Gallery
            photos={list_photos}
        />
    )
}


export default PhotoGallery;