import React, {Component} from 'react';
import {sketches, posters, open_submissions} from "./data";
import Poster from "./Poster";

class Exhibition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            base_url: process.env.PUBLIC_URL + "/media/posters/",
            technical_url: process.env.PUBLIC_URL + "/media/technicalSheet.pdf",
            photo_gallery_url: process.env.PUBLIC_URL + "/photos",
        }

    }

    render() {
        return (
            <section className="about-inner">
                <p className="title_div">Exhibition</p>
                <div style={{width: "50%"}}>
                    <p><span className="text_bold">The Processing Community Day @ Coimbra 2022</span> invited professionals, researchers, students, designers and artists to submit individual or collective projects that visually and/or conceptually explore techniques or subjects related to the theme <span className="text_bold">“Community”</span>.</p>
                    <p>In this <span className="text_bold">PCD@Coimbra</span> edition, we present three different types of artworks: (I) Community modules; (II) Poster; (III) Open Submission.</p>
                    <p> The presented artworks are created exploring programming language in the part of their development process. The open call resulted in 71 submissions. The submissions were double-blind reviewed by a selection jury and 24 artworks are presented (including two performances).</p>
                </div>

                <div className="sub_topic">
                    <h6 className="sub_title_div">Community Modules</h6>
                    <div id={"comModules"}>
                        {sketches.map((sketch) =>
                            <p><a href={sketch.url} style={{textDecoration: "none", color: "#000"}}><span
                                className="sub_sub_title_div">{sketch.name}</span> <br/>{sketch.author}</a></p>
                        )}
                    </div>
                </div>

                <div className="sub_topic s-t">
                    <h6 className="sub_title_div">Open Submissions</h6>
                    <div id={"openSubs"}>
                        {open_submissions.map((open_submission) =>
                        <p><a href={open_submission.url} style={{textDecoration: "none", color: "#000"}}><span
                            className="sub_sub_title_div">{open_submission.name}</span> <br/>{open_submission.author}</a></p>
                        )}
                    </div>
                </div>

                <div className="sub_topic s-t">
                    <h6 className="sub_title_div">Posters</h6>

                    <div style={{width: "100%"}}>
                        {posters.map((poster) =>
                            <Poster url={poster.url} name={poster.name} author={poster.author}/>
                        )}
                    </div>
                </div>

                <div className="sub_topic s-t">
                    <h4 className="sub_title_div">SelectionJury</h4>
                    <p>
                        <p className="m2"><a
                            href="https://linktr.ee/pedamado"
                            target="_blank" rel="noreferrer">Pedro Amado</a> (<a
                            href="https://sigarra.up.pt/fbaup/pt/web_page.inicial" target="_blank" rel="noreferrer">FBAUP</a>)</p>

                        <p className="m2"><a
                            href="https://cdv.dei.uc.pt/authors/catarina-macas/"
                            target="_blank" rel="noreferrer">Catarina Maçãs</a> (<a
                            href="https://www.uc.pt/fctuc/dei/" target="_blank" rel="noreferrer">DEI — FCTUC</a> and <a
                            href="https://www.cisuc.uc.pt/" target="_blank" rel="noreferrer">CISUC</a>)</p>

                        <p className="m2"><a
                            href="https://www.barbarasays.com"
                            target="_blank" rel="noreferrer">António Silveira Gomes</a> (<a
                            href="https://www.ipleiria.pt/esadcr/" target="_blank" rel="noreferrer">ESAD.CR</a> and <a
                            href="https://www.barbarasays.com/" target="_blank" rel="noreferrer">Barbara Says...</a>)</p>
                    </p>
                </div>

                <div className="sub_topic">
                    <h4 className="sub_title_div">Organising Committee</h4>
                    <p>
                        <a href="https://cdv.dei.uc.pt/authors/jessica-parente/">Jéssica Parente</a><br/>
                        <a href="https://cdv.dei.uc.pt/authors/luis-goncalo/">Luís Gonçalo</a><br/>
                        <a href="https://cdv.dei.uc.pt/authors/joao-cunha/"> João Miguel Cunha</a><br/>
                        <a href="https://cdv.dei.uc.pt/authors/pedro-silva/">Pedro Silva</a><br/>
                        <a href="https://sergiorebelo.work">Sérgio Rebelo</a><br/>
                        <a href="https://cdv.dei.uc.pt/authors/tiago-martins/">Tiago Martins</a><br/>
                        <a href="https://cdv.dei.uc.pt/authors/penousal-machado/">Penousal Machado</a><br/>
                    </p>
                </div>

                <div className="sub_topic" style={{width: "50%"}}>
                    <h4 className="sub_title_div">Acknowledgments</h4>
                    <p>
                        Stig Møller Hansen; Rodrigo Neves; António Silveira Gomes; Catarina Maçãs; Pedro Amado; Jorge Ávila; Department of Informatics Engineering of the Faculty of Sciences and Technology of the University of Coimbra (DEI/ FCTUC); Helpdesk of the Department of Informatics Engineering (SIC / DEI / FCTUC); Centre for Informatics and Systems of the University of Coimbra (CISUC); Edmundo Monteiro;
                    </p>
                </div>

                <div className="sub_topic s-t">
                    <a href={this.state.technical_url}>Technical Sheet</a><br/>
                    <a href={this.state.photo_gallery_url}>Photo archive</a>
                </div>


                <div className="sub_topic s-t">
                    <h4 className="sub_title_div">More info</h4>
                    <p>If you have any questions please contact us using the email <a
                        href="mailto:pcdcoimbra@dei.uc.pt">pcdcoimbra(at)dei.uc.pt</a></p>
                </div>
                <p>
                    © 2022
                </p>
            </section>
        );
    }
}

export default Exhibition;